import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Modal } from "@mantine/core";
import styles from "./PageHeader.module.scss";
import Button from "../common/Button";
import Fade from "react-reveal/Fade";

import loadable from "@loadable/component";
import { PlayIcon } from "@heroicons/react/20/solid";

const AnimationComponent = loadable(() =>
  import("../animations/AnimationComponent.js")
);

const PageHeader = ({ title }) => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.heading}>
            <Fade up duration={1000} delay={0} distance="30px" cascade>
              <h1 className={styles.title}>{title}</h1>
            </Fade>
          </div>
        </div>
      </div>
    </>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default PageHeader;
