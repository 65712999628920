import React, { useEffect } from "react";
import PageHeader from "../components/newest/PageHeader";
import Layout from "../components/common/Layout";
import Fade from "react-reveal/Fade";
import MeetTheArtist from "../components/singlePost/MeetTheArtist";

const Newest = ({}) => {
  return (
    <Layout
      meta={{
        description:
          "Museum-quality posters made on thick matte paper. Add a wonderful accent to your room and office with these posters that are sure to brighten any environment.",
        title: "About | LWE Art",
        type: "website",
      }}
      title={"About | LWE Art"}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader title="About LWE" />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <MeetTheArtist />
        </Fade>
      </main>
    </Layout>
  );
};

export default Newest;
