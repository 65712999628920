import React from "react";
import styles from "./MeetTheArtist.module.scss";
import { PostCard } from "../home/BestSellers";

const MeetTheArtist = ({}) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.grid}>
          <div className={styles.imageContainer}>
            <img src="/images/artist.jpg" alt="LWE" />
          </div>
          <div>
            <h2>Meet the artist behind</h2>
            <p>
              All artworks you see on this website is made by Vegard. Vegard
              Løwe is a self-taught artist whose passion for painting began at a
              young age.
            </p>
            <p>
              Meanwhile, he has pursued a professional career in various fields,
              he rediscovered his love for art during the COVID-19 pandemic.
              Painting became his sanctuary and a way to express his innermost
              feelings. Vegard's journey as an artist started in his small
              apartment and quickly grew as people showed interest in his work.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetTheArtist;
